/* eslint-disable @typescript-eslint/no-magic-numbers */

import { LoadDimensions, LoadType } from 'core/models';
import { ConflictAreaColors, VehicleColors } from 'library/styles';
import { IconOptions } from 'modules/maps/helpers';
import { filters } from 'pixi.js';
import { FontName } from '../map-layer-fonts.constant';
import { TagOptions } from './helpers/vehicle-graphic.helper';

export const IconStyle: IconOptions = {
  resourceOptions: { scale: 5 },
  scale: 2.7,
};

// #region Vehicle Types

export const VehicleStyle = {
  Corner: 7,
  BorderSize: 2,
  InitializeIconOffset: 5,
  PauseIconOffset: 40,

  TagLine: 7,
};

export const ForkliftStyle = {
  Color: VehicleColors.ForkliftBody,
  ForkColor: VehicleColors.ForkColor,
  ForkShadowColor: VehicleColors.ForkShadowColor,
  ForkOpacity: 0.5,
  ForkWidth: 5,
};

export const TuggerTrailerStyle = {
  DrawBarColor: VehicleColors.DrawBar,
  DrawBarWidth: 10,
  Corner: 10,
};

export enum TagScale {
  TagScaleLarge = 0.8,
  TagScaleMedium = 0.55,
  TagScaleSmall = 0.4,
  TagScaleExtraSmall = 0.25,
}

export const ManualTagStyle: TagOptions = {
  text: 'M',
  font: FontName.BoldWhite,
  scale: TagScale.TagScaleLarge,
  color: VehicleColors.TagGrey,
};

export const UAgvTagStyle: TagOptions = {
  text: 'U-AGV',
  font: FontName.BoldWhite,
  scale: TagScale.TagScaleMedium,
  color: VehicleColors.TagDS,
};

// https://atc.bmwgroup.net/confluence/pages/viewpage.action?pageId=4324049780
export const LoadTypeDimensions: Record<LoadType, LoadDimensions> = {
  [LoadType.Unknown]: { length: 1200, width: 800 },
  [LoadType.Glt1400X1250]: { length: 1200, width: 800 },
  [LoadType.Uglt1680X1250]: { length: 1650, width: 1250 },
  [LoadType.MSpa1260x1160]: { length: 1350, width: 1160 },
  [LoadType.SeatDeb1000x1600]: { length: 1200, width: 1000 },
  [LoadType.GltPd1200x1000]: { length: 800, width: 1200 },
  [LoadType.GltPd800x1200]: { length: 1200, width: 800 },
};

// #endregion

// #region Vehicle State
export const VehicleLoadedStyle = {
  Color: VehicleColors.Loaded,
  Corner: 15,
  Border: 8,
};

export const VehicleErrorStyle = {
  Color: VehicleColors.Error,
  Opacity: 0.2,
  Size: 150,
};

export const VehicleName = {
  Opacity: 0.7,
  Padding: 4,
  Scale: 0.7,
  Radius: 3,
};

export const VehicleSelection = {
  Opacity: 0.15,
  Border: 3,
  Size: 180,
  Color: VehicleColors.Default,
};

export const VehiclePathStyle = {
  ArrowPoints: 3,
  ArrowSize: 0.15,
  SelectedArrowSize: 0.25,
  Size: 0.05,
  SelectedSize: 0.08,
  Opacity: 1,
};

export const ConflictAreaStyle = {
  OpacityFilter: new filters.AlphaFilter(0.3),
  AwarenessColor: ConflictAreaColors.Awareness,
  DeadLockColor: ConflictAreaColors.DeadLock,
  IntersectionZoneColor: ConflictAreaColors.IntersectionZone,
  ConflictAreaColor: ConflictAreaColors.ConflictArea,
};

export const VehicleDisconnect = {
  PulseDelay: 60,
};
// #endregion
