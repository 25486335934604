import { PathEdgeDto, VehicleConflictAreaDimensions, VehicleDto } from 'core/dtos';
import { Trailer } from 'core/models';
import { Container, Graphics, LINE_CAP, LINE_JOIN, Texture } from 'pixi.js';
import { ContainerEx } from '../../pixi';
import { VehicleGraphicHelper } from '../helpers/vehicle-graphic.helper';
import { TrailerGraphic } from '../trailers/trailer.graphic';
import { TuggerDimensions } from '../vehicle-dimensions.model';
import { VehicleTextures } from '../vehicle-layer-texture.constant';
import { ConflictAreaStyle } from '../vehicle-layer.constant';
import { VehicleMapItem } from './vehicle.graphic';

export abstract class TuggerTrainMapItem extends VehicleMapItem {
  protected dimensions!: TuggerDimensions;
  protected trailerContainer!: Container<TrailerGraphic>;
  private canDrawTuggerZonePath = false;

  get trailerCount(): number {
    return this.trailerContainer ? this.trailerContainer.children.length : 0;
  }

  // #region Create
  protected setup(_vehicle: VehicleDto, itemContainer: ContainerEx): void {
    this.trailerContainer = new Container();

    itemContainer.addChildAtBottom(this.trailerContainer);
  }

  protected createVehicleBackground(): Graphics {
    return VehicleGraphicHelper.createVehicleBase(this.dimensions);
  }

  protected createLoad(): Graphics {
    return new Graphics();
  }

  protected createTrailers(vehicle: VehicleDto): void {
    if (!vehicle.trailers) return;

    this.clearTrailers();

    const graphics = vehicle.trailers.map(t => this.createTrailer(t, this.dimensions));
    graphics.forEach(g => this.trailerContainer.addChild(g));
  }

  protected getTrailerTexture(): Texture {
    return VehicleTextures.tuggerTrailer;
  }

  protected createTrailer(trailer: Trailer, dimensions: TuggerDimensions): TrailerGraphic {
    return new TrailerGraphic(trailer, this.getTrailerTexture(), dimensions.trailer);
  }
  // #endregion

  // #region Update
  update(vehicle: VehicleDto, isTrafficAnalysis = false): void {
    super.update(vehicle, isTrafficAnalysis);

    const vehicleTrailerCount = vehicle.trailers?.length ?? 0;
    if (vehicleTrailerCount > 0) {
      if (this.trailerCount !== vehicleTrailerCount) {
        this.createTrailers(vehicle);
      } else {
        this.updateTrailers(vehicle);
      }
    } else {
      this.clearTrailers();
    }
  }

  private updateTrailers(vehicle: VehicleDto): void {
    if (!this.trailerCount) return;

    const trailers = vehicle.trailers;
    if (trailers) {
      this.trailerContainer.children.forEach((t, index) => {
        const { trailerPosition } = trailers[index];

        t.updateTrailer(trailerPosition);
      });
    }
  }

  clearTrailers(): void {
    this.trailerContainer.removeChildren();
  }

  // #endregion

  // #region Position & Rotation

  protected calculateLabelOffset(
    orientation: number,
    xPositionOffset: number,
    textHeight: number
  ): number {
    return VehicleGraphicHelper.calculateLabelOffset(
      this.dimensions,
      orientation,
      xPositionOffset,
      textHeight
    );
  }

  // #endregion

  // #region Conflict Area

  updateVehicleConflictAreaDimensions(
    vehicleConflictAreaDimensions: VehicleConflictAreaDimensions
  ): void {
    this.conflictArea = vehicleConflictAreaDimensions;
    const { lookAheadArea, stoppingArea, deadlockArea } = vehicleConflictAreaDimensions;

    if (this.canDrawTuggerZonePath) {
      this.collisionArea.clear();
      this.deadlockArea.clear();
      this.stoppingArea.clear();
      return;
    }

    this.handleTrafficManagementAreasForTuggerTrain(
      lookAheadArea,
      this.collisionArea,
      ConflictAreaStyle.ConflictAreaColor,
      this.trafficManagementFilter?.collisionArea
    );

    this.handleTrafficManagementAreasForTuggerTrain(
      stoppingArea,
      this.stoppingArea,
      ConflictAreaStyle.ConflictAreaColor,
      this.trafficManagementFilter?.stoppingArea
    );

    this.handleTrafficManagementAreasForTuggerTrain(
      deadlockArea,
      this.deadlockArea,
      ConflictAreaStyle.DeadLockColor,
      this.trafficManagementFilter?.deadlockArea
    );
  }

  updateVehicleIntersectionZoneConflictAreaDimensions(pathEdges: PathEdgeDto[]): void {
    this.canDrawTuggerZonePath = pathEdges.length > 0;

    this.createTuggerTrainConflictArea(
      this.intersectionZonePath,
      ConflictAreaStyle.IntersectionZoneColor,
      pathEdges
    );

    if (this.conflictArea) {
      this.updateVehicleConflictAreaDimensions(this.conflictArea);
    }
  }

  private handleTrafficManagementAreasForTuggerTrain(
    pathEdges: PathEdgeDto[] | null,
    area: Graphics,
    areaColor: number,
    filter?: boolean
  ): void {
    area.visible = (pathEdges && pathEdges.length > 0) ?? false;

    if (filter && area.visible && pathEdges) {
      this.createTuggerTrainConflictArea(area, areaColor, pathEdges);
    } else if (area) {
      area.clear();
    }
  }

  private createTuggerTrainConflictArea(
    area: Graphics,
    color: number,
    pathEdges?: PathEdgeDto[]
  ): void {
    area.visible = pathEdges && pathEdges.length > 0 ? true : false;
    if (!area.visible) {
      return;
    }
    if (pathEdges) {
      const lineTextureStyle = {
        cap: LINE_CAP.ROUND,
        join: LINE_JOIN.ROUND,
        color: color,
        width: pathEdges[0].width,
        miterLimit: 100,
      };
      area.clear().lineTextureStyle(lineTextureStyle);
      for (const edge of pathEdges) {
        area.lineTextureStyle({ ...lineTextureStyle, width: edge.width * 2 });
        area.moveTo(edge.lineSegment.p0.x, edge.lineSegment.p0.y);
        area.lineTo(edge.lineSegment.p1.x, edge.lineSegment.p1.y);
      }
      area.filters = [ConflictAreaStyle.OpacityFilter];
    }
  }
  // #endregion
}
