import {
  DateString,
  DeviceSubStepModel,
  GuidString,
  HardwareVersion,
  MissionStatus,
  PauseCondition,
  VehicleType,
} from 'core/models';
import { FailureCommentDto } from './failure-comment.dto';

export interface TourChainConfigurationDto {
  id: GuidString;
  workAreaId: GuidString;
  name: string;
  sapInternalRoute: string | null;
  enableRepetitiveTour: boolean;
  triggerType: TourTriggerType;
  vehicleType: VehicleType;
  deviceName: string | null;
  deviceNodeName: string | null;
  deviceNodeValue: string | null;
  hardwareVersion: HardwareVersion | null;
  replenishmentTime: number | null;
  tourConfigurations: TourConfigurationDto[];
}

export interface TourConfigurationDto {
  id: GuidString;
  name: string;
  routeConfigurationId: GuidString | null;
  steps: TourStepConfigurationDto[];
  assignableNodeId: string | null;
  fuelingConfiguration: FuelingConfigurationDto | null;
  pauseConditions: PauseCondition[];
}

export interface FuelingConfigurationDto {
  nodeId: string;
  fuelingThresholdInPercent: number;
  hasArrivalNotificationEnabled: boolean;
}

export type TourStepConfigurationDto =
  | DropTourStepConfigurationDto
  | PickTourStepConfigurationDto
  | GotoTourStepConfigurationDto
  | GotoAndPushSideButtonTourStepConfigurationDto
  | SapStopTourStepConfigurationDto
  | WaitForDeviceTourStepConfigurationDto
  | WaitForSapAcknowledgementTourStepConfigurationDto
  | StartToChargeTourStepConfigurationDto
  | WaitForEndOfStepTourStepConfigurationDto;

export enum TourStepType {
  Goto = 0,
  GotoAndPushSideButton = 1,
  SapStop = 2,
  Pick = 3,
  Drop = 4,
  WaitForDevice = 5,
  WaitForSapAcknowledgement = 6,
  StartToCharge = 7,
  StopToCharge = 8,
  WaitForEndOfStep = 9,
}

export enum TourTriggerType {
  SAP = 0,
  AdHocFromTourConfiguration = 1,
  BeginShiftTour = 2,
  AdhocInstructionFromStep = 3,
  Device = 4,
  CallOff = 5,
  Parking = 6,
  Charging = 7,
}

export interface ArchivedTourDto {
  id: GuidString;
  tourconfigurationId: GuidString;
  name: string;
  vehicleName: string;
  vehicleId: string;
  vehicleType: VehicleType;
  missionId: GuidString;
  missionFailureReasonId: GuidString;
  tourConfigurationName: string;
  sapInternalRoute: string;
  routeConfigurationId: GuidString;
  tourconfigTraceId: GuidString;
  status: MissionStatus;
  trigger: string;
  successfulSteps: number;
  totalSteps: number;
  lastStepType: string;
  lastStepDetail: string;
  createdDateUtc: DateString;
  provisioningTime: DateString;
  completedDateUtc: DateString;
  hasManualSupport: boolean;
  failureComment?: FailureCommentDto[];
}

export interface TourValidationResultDto {
  isValid: boolean;
  reason: InValidationRouteReason | null;
}

export enum InValidationRouteReason {
  WorkAreaNotFound = 0,
  RouteNotFound = 1,
  TourCouldNotBeCompiled = 2,
}

export interface AdHocTourStepsDispatch {
  vehicleId: GuidString;
  routeConfigurationId?: GuidString;
  steps: TourStepConfigurationDto[];
  name: string;
}

export interface TourStepConfigurationDtoBase {
  sequenceId: number;
  stepType: TourStepType;
  raiseEventId: GuidString | null;
}

export interface DropTourStepConfigurationDto extends TourStepConfigurationDtoBase {
  stepType: TourStepType.Drop;
  height: number | null;
}

export interface PickTourStepConfigurationDto extends TourStepConfigurationDtoBase {
  stepType: TourStepType.Pick;
  height: number | null;
}

export interface GotoTourStepConfigurationDto extends TourStepConfigurationDtoBase {
  stepType: TourStepType.Goto;
  nodeId: string;
  nodeGroupId: GuidString | null;
  hasArrivalNotificationEnabled: boolean;
}

export interface GotoAndPushSideButtonTourStepConfigurationDto
  extends TourStepConfigurationDtoBase {
  stepType: TourStepType.GotoAndPushSideButton;
  nodeId: string;
  nodeGroupId: GuidString | null;
  hasArrivalNotificationEnabled: boolean;
}

export interface StartToChargeTourStepConfigurationDto extends TourStepConfigurationDtoBase {
  stepType: TourStepType.StartToCharge;
}

export interface StopToChargeTourStepConfigurationDto extends TourStepConfigurationDtoBase {
  stepType: TourStepType.StopToCharge;
}

export interface SapStopTourStepConfigurationDto extends TourStepConfigurationDtoBase {
  stepType: TourStepType.SapStop;
  hasArrivalNotificationEnabled: boolean;
}

export interface WaitForDeviceTourStepConfigurationDto extends TourStepConfigurationDtoBase {
  stepType: TourStepType.WaitForDevice;
  substeps: DeviceSubStepModel[];
  device: string;
  streamingService: string;
}

export interface WaitForSapAcknowledgementTourStepConfigurationDto
  extends TourStepConfigurationDtoBase {
  stepType: TourStepType.WaitForSapAcknowledgement;
  preStorageLocation: string;
  akz?: string | null;
}

export interface WaitForEndOfStepTourStepConfigurationDto extends TourStepConfigurationDtoBase {
  stepType: TourStepType.WaitForEndOfStep;
  waitEventId: GuidString | null;
}

export interface CallOffTourConfigurationDto {
  tourConfiguration: TourChainConfigurationDto;
  vehicleId: GuidString;
  vehicleName: string;
}

export interface CallOffTourTriggerResult {
  isSuccessful: boolean;
  errorMessage?: string;
}
