export enum MapDataUploadStatus {
  Pending,
  Completed,
}

export enum VehicleOptionStatus {
  Offline = 'vehicleOffline',
  AlreadyActiveMap = 'alreadyActive',
  MapNotAvailableOnVehicle = 'notAvailable',
  Busy = 'busy',
  Available = 'available',
}

export enum VehicleMapDataDialogState {
  UploadVehicleMapData,
  Success,
  Failed,
  PartialSuccess,
  DownloadSuccess,
  EnableMap,
}
