export const MapImages = {
  Rotation_Icon: 'assets/global/maps/RotationHandle.svg',
  Compass_Icon: 'assets/global/maps/Compass.png',
};

export const VehicleLayerImages = {
  Png_Vehicle_Graphic_Tugger_Trailer: 'assets/global/maps/vehicles/TuggerTrailer.png',
  Png_Vehicle_Graphic_Tugger_Trailer_UFrame: 'assets/global/maps/vehicles/TuggerTrailerUFrame.png',

  Vehicle_Graphic_Arrow: 'assets/global/maps/vehicles/VehicleArrow.svg',
  Vehicle_Graphic_NotInitialized: 'assets/global/maps/vehicles/NotInitialized.svg',
  Vehicle_Graphic_Paused: 'assets/global/maps/vehicles/VehiclePaused.svg',
  Vehicle_Graphic_Disconnect: 'assets/global/maps/vehicles/VehicleDisconnect.svg',
};

export const POILayerImages = {
  POI_BASE_STR: 'assets/global/maps/pois/bases/base-str.svg',
  POI_SquareBox: 'assets/global/maps/pois/bases/SquarePoiBox.png',
  POI_LargeBox: 'assets/global/maps/pois/bases/LargePoiBox.png',
  POI_Box: 'assets/global/maps/pois/bases/PoiBox.png',

  POI_Sensor_Free: 'assets/global/maps/pois/icons/SensorFree.png',
  POI_Sensor_Occupied: 'assets/global/maps/pois/icons/SensorOccupied.png',

  POI_WayPoint: 'assets/global/maps/pois/WaypointWithArrow.svg',

  POI_AutomaticConveyor: 'assets/global/maps/pois/icons/AutomaticConveyorLoading.svg',
  POI_ChargingStation: 'assets/global/maps/pois/icons/ChargingStation.svg',
  POI_DollyPlace: 'assets/global/maps/pois/icons/Dolly.svg',
  POI_ParkingSpace: 'assets/global/maps/pois/icons/ParkingPlace.svg',
  POI_ParkingSpaceLarge: 'assets/global/maps/pois/icons/ParkingSpaceLarge.svg',
  POI_TurnTableLarge: 'assets/global/maps/pois/icons/Turntable.svg',
  POI_TurnTableSmall: 'assets/global/maps/pois/icons/TurntableSmall.svg',
  POI_Handover: 'assets/global/maps/pois/icons/Handover.svg',
  POI_AssignableWaypoint: 'assets/global/maps/pois/icons/AssignableWaypoint.svg',
  POI_TripleTurnTable: 'assets/global/maps/pois/icons/TripleTurnTable.svg',
  POI_PalletStationCrosswise: 'assets/global/maps/pois/icons/PalletCrosswise.svg',
  POI_PalletStationLengthwise: 'assets/global/maps/pois/icons/PalletLengthwise.svg',
  POI_RackChangerDropoff: 'assets/global/maps/pois/icons/RackDropoff.svg',
  POI_RackChangerPickup: 'assets/global/maps/pois/icons/RackPickup.svg',
  POI_PrePositioning: 'assets/global/maps/pois/icons/PrePositioning.svg',
};

export const GraphLayerImages = {
  Graph_Node_Fuelling: 'assets/global/maps/nodes/FuelingPoint.svg',
  Graph_Node_Assignment: 'assets/global/maps/nodes/AssignmentPoint.svg',
  Graph_Node_Charging: 'assets/global/maps/nodes/Charging.svg',

  Graph_Node_PickDrop: 'assets/global/maps/nodes/pick&drop.png',
  Graph_Node_Battery: 'assets/global/maps/nodes/battery.png',
  Graph_Node_Parking: 'assets/global/maps/nodes/parking.png',
  Graph_Node_DetectObject: 'assets/global/maps/nodes/detect.png',
  Graph_Node_WaitForTrigger: 'assets/global/maps/nodes/wait_for_trigger.png',
  Graph_Node_Custom: 'assets/global/maps/nodes/custom.png',
  Graph_Node_MoreOptions: 'assets/global/maps/nodes/more2.png',
};

export const ZoneLayerImages = {
  Zone_One_Way: 'assets/global/maps/zones/direction.svg',
};
