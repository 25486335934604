import { VehicleOptionStatus } from 'core/models';

export const EnableMapTones = {
  [VehicleOptionStatus.AlreadyActiveMap]: 'positive', // Base is used for Graphics with Tint
  [VehicleOptionStatus.Available]: 'positive',
  [VehicleOptionStatus.Busy]: 'caution',
  [VehicleOptionStatus.Offline]: 'caution',
  [VehicleOptionStatus.MapNotAvailableOnVehicle]: 'info',
};

export const EnableMapColors = {
  [VehicleOptionStatus.AlreadyActiveMap]: '--ds-color-green-100', // Base is used for Graphics with Tint
  [VehicleOptionStatus.Available]: '',
  [VehicleOptionStatus.Busy]: '--ds-color-yellow-100',
  [VehicleOptionStatus.Offline]: '--ds-color-yellow-100',
  [VehicleOptionStatus.MapNotAvailableOnVehicle]: '--ds-color-blue-100',
};
