import { SoftwareUpdateVersionStatus, VehicleOptionStatus } from 'core/models';
import { Icons } from 'library/constants';

const softwareUpdateStatus = {
  [SoftwareUpdateVersionStatus.Unknown]: Icons.Question,
  [SoftwareUpdateVersionStatus.Outdated]: Icons.Warning,
  [SoftwareUpdateVersionStatus.Approved]: Icons.Check,
  [SoftwareUpdateVersionStatus.Test]: Icons.Exchange,
};

const enableMap = {
  [VehicleOptionStatus.Offline]: Icons.Offline,
  [VehicleOptionStatus.Available]: Icons.CheckDouble,
  [VehicleOptionStatus.AlreadyActiveMap]: Icons.CheckDouble,
  [VehicleOptionStatus.MapNotAvailableOnVehicle]: Icons.Information,
  [VehicleOptionStatus.Busy]: Icons.Alert,
};

export const IconCollection = {
  softwareUpdateStatus,
  enableMap,
};
