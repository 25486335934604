import {
  DateString,
  GraphParkingChargingType,
  GuidString,
  Node,
  NodeOccupancyStatus,
  Pose2D,
} from 'core/models';

export interface NodeDto {
  nodeId: string;
  nodeName: string;
  nodePosition: Pose2D;
  parkingChargingType: GraphParkingChargingType;
  actions?: ActionDto[];
  isReTrackingPoint: boolean;
  isParking?: boolean;
  isCharging?: boolean;
  reTrackingOrientation: number; //Radians
  nodeDescription?: string;
  color?: number;
  map: {
    id: GuidString;
    navigationLayerId: GuidString;
  };
  occupancy?: {
    status: NodeOccupancyStatus;
    vehicleName?: string;
    statusUpdateTime?: DateString;
    vehicleId?: GuidString;
    managementType?: OccupancyManagementType;
  };
}

export interface EdgeControlPoint {
  x: number;
  y: number;
  weight: number;
}

export interface EdgeDto {
  edgeId: GuidString;
  edgeName: string;
  startNodeId: GuidString;
  endNodeId: GuidString;
  trajectory?: EdgeTrajectory;
}

export interface EdgeTrajectory {
  controlPoints: EdgeControlPoint[];
  degree: number;
  knotVector: number[];
}

export interface NurbDto {
  startNode: Node;
  endNode: Node;
  points: number[][];
  knots: number[];
  weights: number[];
  degree: number;
  length?: number;
}

export interface LayoutDto {
  id: GuidString;
  name?: string;
  description?: string;

  mapId: GuidString;
  navigationLayerId: GuidString;

  nodes: NodeDto[];
  edges: EdgeDto[];
}

export interface NodesAndEdgesDto {
  nodes: NodeDto[];
  edges: EdgeDto[];
}

export interface ViaPointRequestModel {
  selectedNodeId: GuidString;
  switchNodeId: GuidString;
}

export interface ActionGroupDto extends ActionDto {
  group: NodeActionTypeGroup;
  isGrouped: boolean;
}

export interface ActionDto {
  actionType: NodeActionType;
  actionDescription: string | null;
  blockingType: ActionBlockingType;
  requirementType: ActionRequirementType;
  actionParameters: ActionParameterDto[];
  nodeId?: string;
  edgeId?: string;
}

export interface ActionParameterDto {
  key: string;
  value: string;
}

export enum ActionBlockingType {
  None = 0,
  Soft = 1,
  Hard = 2,
}

export enum ActionRequirementType {
  Required = 0,
  Conditional = 1,
  Optional = 2,
}

export interface NodeOccupancyStatusChangeDto {
  nodeId?: string;
  vehicleId: GuidString;
  status: NodeOccupancyStatus;
  statusUpdatedOnUtc: DateString;
  workAreaId: GuidString;
  vehicleName?: string;
  managementType?: OccupancyManagementType;
}

export enum OccupancyManagementType {
  Unknown = 0,
  NodeGroup = 1,
  ParkyChargy = 2,
}

export interface UnbookNodeResult {
  isCancelled: boolean;
  nodeIds: GuidString[];
}

export enum NodeActionType {
  StartCharging = 'startCharging',
  StopCharging = 'stopCharging',
  InitPosition = 'initPosition',
  EnableMap = 'enableMap',
  Pick = 'pick',
  Drop = 'drop',
  DetectObject = 'detectObject',
  FinePositioning = 'finePositioning',
  WaitForTrigger = 'waitForTrigger',
  More = 'more',
}

export enum NodeActionTypeGroup {
  StartStopCharging = 'startStopCharging',
  DetectObjectInitPositionFinePositioning = 'detectObjectAndPositioning',
  PickDrop = 'pickDrop',
  WaitForTrigger = 'waitForTrigger',
  Custom = 'custom',
}

export enum NodeActionOrder {
  PickDrop = 1,
  StartStopCharging = 2,
  DetectObjectInitPositionFinePositioning = 3,
  WaitForTrigger = 4,
  Custom = 5,
}

export enum ActionTypeIcon {
  Info = 'arrow_down',
  Wait = 'time',
  Battery = 'battery',
  Focus = 'focus',
  Maximize = 'arrows_maximize',
  Custom = 'star_fill',
}

export enum IconBackgroundSize {
  SingleIcon,
  MultiIcon,
}
